.section--small {
  .col {
    @include make-col(8);
    @include make-col-offset(2);
  }
}

.section--center {
  > .container > .row > .col {
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(sm) {
      flex-wrap: wrap;
    }
  }
}
