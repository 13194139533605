@import './bwi-variables';
@import './bootstrap';

// num can be 1-6, see bootstrap var $spacers
@function get-spacer($num: 3) {
  @return map-get($spacers, $num);
}

@mixin absolute-zero($padding: 0px) {
  position: absolute;
  top: $padding;
  bottom: $padding;
  left: $padding;
  right: $padding;
}

%centerize-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

%mb-module {
  margin-bottom: get-spacer(3);
  &:last-child {
    margin-bottom: 0;
  }
}

%mb-col {
  margin-bottom: get-spacer(4);
  @include media-breakpoint-down(sm) {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

%mb-row {
  margin-bottom: get-spacer(5);
  &:last-child {
    margin-bottom: 0;
  }
}

@import 'animate.css/animate';

@import 'swiper/components/pagination/pagination';
@import 'swiper/components/navigation/navigation';
@import 'swiper/swiper';

@import 'atoms/borders';
@import 'atoms/hr';
@import 'atoms/section';
@import 'atoms/typo';
@import 'atoms/margins';

@import 'modules/image-divider';
@import 'modules/image-quote';
@import 'modules/image-text';
@import 'modules/top-arrow';
@import 'modules/quote';
@import 'modules/video-player';
@import 'modules/icon-text';
@import 'modules/kpi';
@import 'modules/infobox';
@import 'modules/accordion';
@import 'modules/person';
@import 'modules/flex-image';
@import 'modules/interview';
@import 'modules/intro';

//reboot
@import 'modules/navigation';
@import 'modules/kvslider';
@import 'modules/pagination';
@import 'modules/footer';
@import 'modules/callout';
@import 'modules/infographic-steps';
@import 'modules/infographic-scroll';
@import 'modules/structured-content';
@import 'modules/twitter';
@import 'modules/image-bubbles';
@import 'modules/to-overview';
@import 'modules/cookie-consent';
//example
@import './example-page';
@import './custom-route-styles';
@import './custom-animations';
@import './toggle';

html {
  touch-action: manipulation;
}

body {
  font-size: $font-size-base * 1.125;
  //hyphens: auto;
  overflow-x: hidden;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  @include media-breakpoint-down(sm) {
    background-image: none !important;
  }
}

.App {
  position: relative;
}

.container .container {
  padding: 0;
}
section {
  // overflow-x: hidden; // TODO: check - Markus had an issue with complete overflow
  // overflow: hidden;
}

main > section {
  // margin-bottom: 6rem;
  overflow: hidden;
}

.image-divider {
  margin-top: get-spacer(4);
  margin-bottom: get-spacer(4);
  min-height: 50vh;
  @include media-breakpoint-down(sm) {
    min-height: 0.416666667vw;
  }
}

p.lead-in {
  @extend .lead;
}

p.highlight {
  @include font-size($h3-font-size);
  font-weight: $font-weight-bold;
}

p.pull-right {
  float: right;
}

p:empty {
  margin: 0;
}

b,
strong {
  font-weight: 700;
}

ul,
ol {
  margin-bottom: $paragraph-margin-bottom;
}

img {
  max-width: 100%;
  height: auto;
}

.header {
  z-index: 3;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  position: relative;
}

main {
  min-height: 0;
  max-height: 0;
  overflow: hidden;
}

.animate__initial {
  opacity: 0;
}

.bg-black {
  background-color: $bg-black;
}

p:last-child {
  margin-bottom: 0;
}

.btn.btn-dark {
  border-color: white;
  color: white;
}

.btn span + img {
  padding-left: 0.5rem;
}

.btn img + span {
  padding-left: 0.5rem;
}

.btn span {
  display: inline-block;
}

.btn {
  @extend %mb-module;
}
video {
  max-width: 100%;
}

.mood-video__wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: percentage(2/3);
    background: linear-gradient(
      to bottom,
      $black 0%,
      rgba($black, 0.4) 66%,
      transparent 95%
    );
    z-index: 1;
  }
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: percentage(1/3);
    background: linear-gradient(to top, $black 0%, rgba($black, 0) 100%);
    z-index: 1;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

section#main {
  background-color: $black;
}

.image__copyright {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: $font-size-xs;
  font-style: normal;
  color: rgba(255, 255, 255, 0.6);
  background-color: rgba(0,0,0,0.5);
  padding: 0.25rem;
  line-height: 1;
  z-index: 1;
  @include media-breakpoint-down(sm) {
    right: 0;
    font-size: 8px;
  }
}