.-borderLeft {
  border-left: $border-width solid $border-color;
}

.-borderRight {
  border-right: $border-width solid $border-color;
}

.-borderTop {
  border-top: $border-width solid $border-color;
}

.-borderBottom {
  border-bottom: $border-width solid $border-color;
}
