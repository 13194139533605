.structured-content {
  position: relative;
  transform: translateX(0);
}
.structured-content__wrapper {
  @include make-row();
}
.structured-content__bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &.object-fit-polyfill {
    overflow: visible !important;
  }
  img {
    width: 100%;
    height: 700px;
    object-fit: none;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }

  &.bgpos--none {
    img {
      height: 100%;
      object-position: center top;
    }
  }

  &.bgpos--left {
    img {
      object-position: left;
      transform: translateX(-25%);
      @include media-breakpoint-down(md) {
        transform: translateX(-50%);
      }
      @include media-breakpoint-down(sm) {
        transform: translateX(0);
      }
    }
  }

  &.bgpos--right {
    img {
      object-position: right;
      transform: translateX(25%);
      @include media-breakpoint-down(md) {
        transform: translateX(50%);
      }
      @include media-breakpoint-down(sm) {
        transform: translateX(0);
      }
    }
  }
}

#intro {
  margin-top: calc(1rem + 125px);
  @include media-breakpoint-down(sm) {
    margin-top: calc(1rem + 75px);
    background-size: cover;
  }
}

#intro > .structured-content > .structured-content__bg {
  display: none;
}

.structured-content__bg {
  @extend .container;
}

#intro
  > .structured-content
  > .container
  > .row
  > .col
  > .structured-content__wrapper {
  border-top: 2px solid white;
  padding-top: 2rem;
  @include media-breakpoint-down(sm) {
    border-top-width: 0px;
  }
}

.column--left {
  @include make-col-ready();
  @include make-col(12);
  .layout--2col & {
    @include make-col(6);
  }
  @include media-breakpoint-down(sm) {
    &,
    .layout--2col & {
      @include make-col(12);
    }
    .layout--2col & {
      margin-bottom: get-spacer(4);
    }
  }
}

.column--right {
  @include make-col-ready();
  @include make-col(6);
  @include media-breakpoint-down(sm) {
    @include make-col(12);
  }
}
