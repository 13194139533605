.accordion__item {
  margin-bottom: get-spacer(2);
  display: flex;
  flex-direction: column;
  align-items: center;
  &.-align-left {
    align-items: flex-start;
  }
}
.accordion__toggle {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: get-spacer(3) get-spacer(2);

  &.toggle--left {
    flex-direction: row-reverse;
  }

  &.toggle--top {
    flex-direction: column;
  }
}

.accordion__body {
  padding: 0;
  margin-top: get-spacer(3);
  @include bwi-border($white);
  max-width: 100%;
  &.-no-border {
    border: none;
  }
}

.accordion__body__inner {
  padding: get-spacer(3);
}

.accordion__title {
  font-weight: bold;
  .toggle--top & {
    margin-top: get-spacer(3);
  }
  font-size: $font-size-base * 1.125;
  line-height: 1.2;
  transform: translateY(-1px);
  .toggle--top & {
    order: 2;
  }
}

.accordion__toggle__icon {
  width: 32px;
  height: 32px;
  transition: $transition-base;
  position: relative;
  border-radius: 50%;
  border: 2px solid $white;
  flex: 1 0 auto;
  .toggle--right & {
    margin-left: get-spacer(3);
  }

  .toggle--left & {
    margin-right: get-spacer(3);
  }

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    background-color: $white;
    transition: $transition-base;
  }
  &:before {
    top: 50%;
    height: 16px;
    width: 2px;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  &:after {
    left: 50%;
    width: 16px;
    height: 2px;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &:hover {
    transform: scale(1.1);
    &:before,
    &:after {
      background-color: $white;
    }
  }

  .accordion__toggle--active & {
    &:before {
      display: none;
    }
  }

  .toggle--top & {
    order: 1;
  }
}
