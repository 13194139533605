@import 'simplebar/src/simplebar';

.simplebar-track.simplebar-horizontal {
  background: #8b8b8b;
  height: 25px;

  border-radius: 12px;
  z-index: 2;
  box-shadow: 0px -20px 20px 10px #000;

  .simplebar-scrollbar {
    height: 21px;
    &::before {
      line-height: 20px;
      content: '← Ziehen →';
      color: $color-black;

      font-size: 80%;

      background: $color-white;
      opacity: 1;
      border: 1px solid $color-black;
      border-radius: 11px;

      text-align: center;
    }
  }
  @media (pointer: coarse) and (hover: none) {
    display: none;
  }
  @include media-breakpoint-down (xs) {
    display: none;
  }
}

.infographic__scroll__mobile-indicator {
  display: none;
  // @media (pointer: coarse) and (hover: none) {
  //   display: block;
  //   position: absolute;
  //   bottom: 0;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   opacity: 0.5;
  //   font-size: $small-font-size;
  // }
}

.infographic__scroll__wrapper, .infographic__scroll__svg__wrapper {
  height: 751px;
  max-height: 75vh;
  @include media-breakpoint-down(xs) {
    max-height: 450px;
    height: 450px;
  }
}
.infographic__scroll__wrapper {
  position: relative;
}
.infographic__scroll__svg__wrapper {
  position: absolute;
  top: 0;
  left: 0;
}
.infographic__scroll__bg {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
  max-height: none;
}

.infographic__scroll__left-mask,
.infographic__scroll__right-mask {
  pointer-events: none;
  display: block;
  position: absolute;
  top: 0;
  bottom: 25px;
  content: '';
  // background-color: rgba(0, 0, 0, 0.8);
  width: 25%;
  z-index: 1;
  @include media-breakpoint-down(md) {
    //width: 25% !important;
  }
  @include media-breakpoint-down(sm) {
    width: 25px !important;
  }
  @media (pointer: coarse) and (hover: none) {
    bottom: 0;
  }
}
.infographic__scroll__left-mask {
  left: 0;
  background: linear-gradient(90deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7) 30%);
  border-right: 1px dotted rgba(255, 255, 255, 0.5);
}

.infographic__scroll__right-mask {
  right: 0;
  background: linear-gradient(270deg, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.7) 30%);
  border-left: 1px dotted rgba(255, 255, 255, 0.5);
}

.infographic__scroll {
  position: relative;
  overflow: hidden;

  cursor: grab;
  &:active {
    cursor: grabbing;
  }

  @include media-breakpoint-down(sm) {
    overflow: visible;
  }

  svg {
    [id^='btn_'] {
      cursor: auto;
      &[data-hover='true'] {
        cursor: pointer;
      }
    }
  }
}

.popups {
  max-height: 0;
}

.popup {
  z-index: 2;
  transition: opacity 350ms ease;
  opacity: 0;
  pointer-events: none;
  &[data-show='true'] {
    pointer-events: all;
    cursor: auto;
    display: block;
    opacity: 1;
    .popup__close {
      display: flex;
    }
  }

  background: rgba(0, 0, 0, 0.8); // TODO: is there a variable/style
  border: 1px solid white;
  border-bottom-left-radius: 2rem; // TODO: is there a variable/style
  border-top-right-radius: 2rem; // TODO: is there a variable/style
  padding: 1.5rem 1.5rem; // TODO: is there a variable/style

  h3 {
    padding-right: 2rem;
  }

  max-width: 585px;
  @include media-breakpoint-down(sm) {
    max-width: 320px;
  }

  em {
    @extend .image__copyright;
  }
}

.popup__close {
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 1rem;
  right: 1rem;
  border: 1px solid white;
  border-radius: 50%;
  // cross
  &:before,
  &:after {
    content: '';
    display: block;
    width: 50%;
    height: 1px;
    background: white;
    transform-origin: center;
  }
  &:before {
    transform: translateY(1px) rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
