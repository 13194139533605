$col-marker: rgba(255, 0, 255, 1);

.ex__text {
  color: $col-marker;
  font-size: 80%;
}

.ex__panel {
  border: 1px solid $col-marker;
  padding: 1rem;
  margin-bottom: get-spacer(5);
  .-full-width & {
    padding-left: 0;
    padding-right: 0;
  }
}

.ex__toggle {
  transition: all 200ms ease-in-out;
  position: absolute;
  display: inline-block;
  text-decoration: none;
  border-radius: 2px;
  transform: translate(calc(-100% - 1rem), 18px);
  &.ex__toggle--hidden {
    transform: translate(calc(-100% - 1rem), 0px);
  }
  &:hover {
    text-decoration: none;
    box-shadow: 0px 0px 4px 2px $col-marker;
  }
}

.-full-width {
  .ex__toggle {
    z-index: 1;
    position: relative;
    float: left;
    transform: none;
    margin-right: 0.25rem;
    &.ex__toggle--hidden {
      transform: translateY(2px);
    }
  }
}
