.image-text--reverse {
  .image-text__row {
    flex-direction: row-reverse;
  }

.col {
  @extend %mb-col;
  @include media-breakpoint-down(sm) {
    @include make-col(12);
  }
}
}

