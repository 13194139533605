.to-overview__wrapper {
  transition: $transition-base;
  transition-delay: 0.5s;

  transform: translateY(100px);

  opacity: 0;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;

  .-show-content & {
    opacity: 1;
    transform: translateY(0px);
  }

  > .container {
    position: relative;
  }
}

.to-overview {
  transform: translateY(calc(-100% - 1rem));
  position: absolute;
  right: 15px; // sames as logo
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.to-overview__icon {
  margin-bottom: get-spacer(2);
}

.to-overview__label {
  font-size: 80%;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
