.infographic-steps {
  overflow: hidden;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}

.bwiapi_info2 .col {
  @include media-breakpoint-down(sm) {
    padding-left: 0;
    padding-right: 0;
  }
}

.infographic-steps__wrapper {
  margin-bottom: 1rem;
   > div {
    position: relative;
    &:before {
      max-width: 100%;
      content: '';
      display: block;
      padding-top: percentage(480 / 1110);
      @include media-breakpoint-down(sm) {
        padding-top: calc(250vw / 1110 * 480);
      }
    }
    > svg {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      width: 100%;
      @include media-breakpoint-down(sm) {
        width: 250vw;
        height: calc(250vw / 1110 * 480);
        transform: translateX(-75vw);
      }
    }
  }
}

.infographic-steps__navigation {
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  .buttons__wrapper {
    min-height: 32px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      top: 0;
      display: block;
      height: 1px;
      background-color: $white;
      transform: translateY(16px);
    }
  }

  .step__button {
    padding: 0;
    transition: $transition-base;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    outline: none !important;
    border: 1px solid $white;
    background-color: black;
    color: $white;
    position: relative;
    transform: translateZ(1px);
    transform-origin: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      background-color: $red;
      border-color: $red;
      transform: scale(1.2);
    }
    &:hover {
      transform: scale(1.2);
    }

    & + .step__button {
      margin-left: 1.5rem;
      @include media-breakpoint-down(sm) {
        margin-left: 0.5rem;
      }
    }
  }

  @include media-breakpoint-down(xs) {
    .buttons__wrapper {
      flex-wrap: wrap;
      max-width: 75%;
      &:before {
        display: none;
      }
    }
    .step__button {
      margin-bottom: 10px;
    }
  }
}

.infographic-steps__plus {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 0;
  background: $color-red;
  border: 1px solid white;
  border-radius: 50%;
  overflow: hidden;
  text-indent: 100vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: $transition-base;
  transform-origin: center;

  outline: none !important;

  &:hover {
    transform: scale(1.2);
  }

  &::before,
  &:after {
    content: '';
    display: block;
    width: 66%;
    height: 1px;
    background: white;
    transform-origin: center;
  }
  &:before {
    transform: rotate(90deg);
  }
  &:after {
    transform: translateY(-1px);
  }
}

.infographic-steps__step_content {
  transition: $transition-base;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: get-spacer(4);
}

.infographic-steps__step__headline {
  margin-bottom: 0;
  font-weight: $font-weight-bold;
}

.infographic-steps__step__text {
  // color: $color-red;
}
