.person {
  @include make-row();
  @extend %mb-module;
  align-items: center;
  flex-direction: row;

  .image__copyright {
    position: relative;
    writing-mode: initial;
    right: initial;
    bottom: initial;
    margin-bottom: 1rem;
  }
}

.person__image__wrapper {
  @include make-col-ready();
  @include make-col(4);
  height: 60vh;

  .image--right & {
    order: 2;
  }

  @include media-breakpoint-down(sm) {
    order: 1 !important;
    @include make-col(12);
  }
}

.person__content__wrapper {
  @include make-col-ready();
  @include make-col(8);
  .image--right & {
    order: 1;
  }

  .accordion__toggle {
    padding-left: 0;
    align-items: left;

    max-width: 75%;
    @include media-breakpoint-down(md) {
      max-width: none;
    }
  }
  .accordion__body {
    padding-left: 0;
    margin-bottom: 1rem;
  }

  @include media-breakpoint-down(sm) {
    order: 2 !important;
    @include make-col(12);
    display: flex;
    flex-direction: column;
    .image__copyright {
      order: -1;
      position: absolute;
      top: -1rem;
      right: 0;
    }
  }
}

.person__image {
  position: absolute;
  right: 0;
  max-width: 700px;
  max-height: 700px;
  height: 100%;
  .image--right & {
    right: auto;
    left: 0;
  }

  @include media-breakpoint-down(md) {
    transform: translateX(15%);
    .image--right & {
      transform: translateX(-15%);
    }
  }
}

.person__name {
  font-weight: $font-weight-bold;
  margin-top: get-spacer(3);
}

.person__meta {
  margin-bottom: get-spacer(3);
}
