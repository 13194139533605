h2 {
  margin-bottom: get-spacer(5);
  @include media-breakpoint-down(sm) {
    margin-bottom: get-spacer(4);
  }
}

h3 {
  @include media-breakpoint-down(sm) {
    font-size: 1.375rem;
  }
}
