.flex-image {
  @extend %mb-module;

  &.flex-image--round {
    border: 2px solid $white;
    border-radius: 50%;
    img {
      padding: get-spacer(2);
      border-radius: 50%;
    }
  }
}
