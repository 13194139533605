.swiper-container {
  width: 100%;
  height: 100%;
  background-color: $black;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 30vh;
    background: linear-gradient(to bottom, $gradient-red);
    z-index: 4;
  }
  .swiper-wrapper {
    z-index: 3;
  }
}

.swiper-navigation-container .swiper-button-prev,
.swiper-navigation-container .swiper-button-next {
  width: 100px !important;
}

.swiper-navigation-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 2rem;
  z-index: 4;
  .swiper-button-prev,
  .swiper-button-next {
    position: relative;
    // border-radius: 50%;
    // border: 2px solid $color-white;
    width: 2rem;
    height: 2rem;
    transition: $transition-base;
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        transform: scale(1.2);
      }
    }

    // &:hover {
    // }
    // &:active,
    // &:focus {
    //   transform: scale(1);
    // }
  }

  .swiper-navigation__bar {
    @include media-breakpoint-down(xs) {
      margin-bottom: get-spacer(3);
    }
  }

  .btn__read {
    margin-bottom: get-spacer(6);
    @media (max-height: 900px) {
      margin-bottom: get-spacer(5);
    }
    @media (max-height: 700px) {
      margin-bottom: get-spacer(4);
    }
    @media (max-height: 500px) {
      margin-bottom: get-spacer(3);
    }
    @media (max-width: 575.98px) and (orientation: portrait) {
      margin-bottom: get-spacer(5);
    }
  }
  .swiper-button-prev:after {
    display: none;
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0.5;
  }

  .swiper-pagination-bullet {
    opacity: 0.5;
  }

  .swiper-button-next,
  .swiper-button-prev {
  }

  .swiper-button-label {
    font-size: 14px;
    // @include media-breakpoint-down(xs) {
    //   display: none;
    // }
  }

  .swiper-button-next:after,
  .swiper-button-prev:before {
  }

  .swiper-button-next {
    text-align: right;
  }

  .swiper-button-prev {
    text-align: left;
  }

  .swiper-button-next:after {
    content: url(./../../icon-top.svg);
    transform: rotate(90deg);
    margin-left: 0.5rem;
  }

  .swiper-button-prev:before {
    content: url(./../../icon-top.svg);
    transform: rotate(-90deg);
    margin-right: 0.5rem;
  }
  .swiper-pagination {
    position: relative;

    .swiper-pagination-bullet {
      background: $color-black;
    }
    .swiper-pagination-bullet-active {
      background: $color-white;
    }

    .swiper-pagination-bullet + .swiper-pagination-bullet {
      margin-left: 8px;
    }

    @include media-breakpoint-down(xs) {
      position: absolute;
      bottom: -2rem;
      left: 0px;
      right: 0px;
    }
  }
}

.slide__fg {
  display: block;
  background: #000;
  @include absolute-zero();
  z-index: 2;
  opacity: 1;
  pointer-events: none;
}

.swiper-video-container {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.slide__bg {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 75% 50%; // on the x cut more on the right side = more space on center
    .slide-0 & {
      height: auto;
      margin-top: 60px; // the nav height
      object-fit: contain;
      object-position: center;
    }
  }
}

.slide__content__wrapper {
  z-index: 2;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.slide__content {
  .btn {
    margin: 9rem 0;
    @include media-breakpoint-down(sm) {
      margin: 5rem 0;
    }
  }
}
.slide__subline {
  @include font-size($h3-font-size);
  font-weight: $font-weight-base;
  @media (max-height: 500px) {
    @include font-size($h4-font-size);
  }
}

.slide__headline {
  @include font-size($display2-size);
  margin-bottom: 0;
  font-weight: $font-weight-base;
  @media (max-height: 500px) {
    @include font-size($h2-font-size);
  }
  .slide-0 & {
    @include font-size($display0-size);
    @media (max-height: 900px) {
      @include font-size($display1-size);
    }
    @media (max-height: 500px) {
      @include font-size($display2-size);
    }
  }
}

.slide__overline {
  @include font-size($display5-size);
  font-weight: $font-weight-base;
  margin-bottom: get-spacer(6);
  @media (max-height: 900px) {
    margin-bottom: get-spacer(5);
  }
  @media (max-height: 700px) {
    margin-bottom: get-spacer(4);
  }
  @media (max-height: 500px) {
    margin-bottom: get-spacer(3);
  }
}

.swiper-slide {
  .image__copyright {
    background-color: transparent;
    writing-mode: vertical-rl;
    max-height: 50vh;
    @include media-breakpoint-down(md) {
      max-height: 60vh;
    }
  }
}