.icon-text {
  margin-bottom: get-spacer(6);
  &:last-child {
    margin-bottom: 0;
  }

  .icon-text__icon,
  .icon-text__text {
    @include make-col-ready();
  }

  // SMALL
  &.icon-text--top-sm,
  &.icon-text--left-sm {
    .icon-text__icon img {
      width: auto;
      height: 70px;
      max-width: 100%;
    }
  }

  // BIG
  &.icon-text--top,
  &.icon-text--left {
    .icon-text__icon img {
      width: auto;
      height: 140px;
      max-width: 100%;
    }
  }

  // TOP
  &.icon-text--top-sm,
  &.icon-text--top {
    .icon-text__icon {
      text-align: center;
      @include make-col(12);
      margin-bottom: get-spacer(4);
    }
    .icon-text__text {
      @include make-col(12);
    }
  }

  // LEFT
  &.icon-text--left {
    .icon-text__icon {
      @include make-col(2);
    }
    .icon-text__text {
      @include make-col(10);
    }
  }
  &.icon-text--left-sm {
    .icon-text__icon {
      @include make-col(1);
    }
    .icon-text__text {
      @include make-col(11);
    }
  }
}
