$color-red: #ff0000;
$color-black: #000000;
$color-white: #ffffff;
$color-oslo-grey: #969797;
$color-gallery: #ebebeb;
$color-havelock-blue: #519ce1;

$gradient-red: rgba(#ff0000, 0) 0%, rgba(#ff0000, 0.5) 100%;

$bg-black: rgba($color-black, 0.9);
$bg-white: rgba($color-white, 0.2);

$nav-height: 90px;

$show-content-transition: all 1s ease-in-out;

$easeSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeCubic: cubic-bezier(0.12, 0.52, 0.56, 0.98);

//for swiper
$themeColor: $color-white;
$colors: (
  'white': #ffffff,
  'black': #000000,
);

@mixin bwi-border($color) {
  border: 2px solid $color;
  border-bottom-right-radius: get-spacer(4);
  border-top-left-radius: get-spacer(4);
}
