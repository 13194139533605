.cookie-consent {
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color-white, 0.4);
  align-items: center;
  justify-content: center;
  z-index: 4;

  @include media-breakpoint-down(sm) {
    align-items: flex-end;
  }

  .cookie-consent__content {
    display: flex;
    flex-direction: column;
    background-color: $color-black;
    padding: get-spacer(5);
    max-width: 650px;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      padding: get-spacer(3);
    }
  }

  label + label {
    margin-top: get-spacer(2);
  }
}

.cookie-consent__button__wrapper {
  margin-top: get-spacer(5);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  a {
    flex: 1;
  }
  .btn {
    margin: 0;
    + .btn {
      margin-left: get-spacer(3);
    }
  }

  @include media-breakpoint-down(sm) {
    flex-direction: column;
    align-items: stretch;
    margin-top: get-spacer(3);
    a, .btn, .btn + .btn {
      margin: 0;
    }

    .btn, .btn+.btn {
      margin-top: get-spacer(3);
    }
  }
}

.cookie-consent__links {
  margin-top: get-spacer(5);
  font-weight: $font-weight-bold;
  a {
    text-decoration: none;
    &:hover, &:active, &:focus {
      text-decoration: none;
      color: $color-red;
    }
  }
  a + a {
    margin-left: get-spacer(3);
  }

  @include media-breakpoint-down(sm) {
    text-align: center;
  }
}