.image-quote__left {
  display: flex;
  align-items: center;
  @include make-col(10);
  @include make-col-offset(2);
  padding-top: get-spacer(6);
  padding-bottom: get-spacer(6);
}

.image-quote__right {
  @include make-col(6);
  position: absolute;
  left: 0;
  bottom: 0;
  > img {
    transform: translateX(-16,66%);
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
}
