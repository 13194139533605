.pagination {
  background: $color-black;
  padding-top: get-spacer(6);
}

.pagination__wrapper {
  @include make-col-offset(2);
  @include make-col(8);
  @include media-breakpoint-down(md) {
    @include make-col-offset(0);
    @include make-col(12);
  }
  > .row {
    align-items: center;
  }
}

.pagination__col--right {
  justify-content: flex-end;
}
.pagination__col--center{
  @include media-breakpoint-down(sm) {
    order: 3;
    @include make-col(12);
  }
}

.pagination__link {
  display: flex;
  align-items: center;
  // line-height: 32px;

  &.-inactive {
    pointer-events: none;
    opacity: 0.5;
  }

  span {
    position: relative;
    top: -1px;
  }
  text-decoration: none;

  &:before,
  &:after {
    width: 32px;
    height: 32px;
  }
}

.pagination__link--prev {
  &:before {
    display: block;
    content: url(./../../icon-top.svg);
    transform: rotate(-90deg);
    margin-right: 0.5rem;
  }
}

.pagination__link--next {
  justify-content: flex-end;
  text-align: right;
  &:after {
    display: block;
    content: url(./../../icon-top.svg);
    transform: rotate(90deg);
    margin-left: 0.5rem;
  }
}

.pagination__bullets {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: get-spacer(3) 0;
  .pagination__bullet {
    @extend .swiper-pagination-bullet;
    background-color: $color-white;

    &.active {
      opacity: 0.5;
    }
  }
  .pagination__bullet__link + .pagination__bullet__link {
    .pagination__bullet {
      margin-left: 8px;
    }
  }
}
