%bwi-py {
  padding-top: get-spacer(5);
  padding-bottom: get-spacer(5);
  @include media-breakpoint-down(md) {
    padding-top: get-spacer(4);
    padding-bottom: get-spacer(4);
  }
  @include media-breakpoint-down(sm) {
    padding-top: get-spacer(4);
    padding-bottom: get-spacer(4);
  }
}

%bwi-pb {
  margin-bottom: get-spacer(5);
  @include media-breakpoint-down(md) {
    margin-bottom: get-spacer(4);
  }
  @include media-breakpoint-down(sm) {
    margin-bottom: get-spacer(4);
  }
  &:last-child {
    margin-bottom: 0 !important;
  }
}

%bwi-pt {
  margin-top: get-spacer(5);
  @include media-breakpoint-down(md) {
    margin-top: get-spacer(5);
  }
  @include media-breakpoint-down(sm) {
    margin-top: get-spacer(5);
  }
  &:first-child {
    margin-top: 0 !important;
  }
}

#intro > section:first-child {
  padding-top: 0px;
}
#main > section:last-child {
  padding-bottom: 0px;
}


main > section > section {
  @extend %bwi-py;
}

.column--left,
.column--right {
  > div,
  > section {
    @extend %bwi-pt;
  }

  .row__bwiapi_text + .structured-content.layout--2col {
    margin-top: get-spacer(5); // sames as h2 margin bottom
    @include media-breakpoint-down(sm) {
      margin-top: get-spacer(4);
    }
  }

  .row__bwiapi_image + .row__bwiapi_expandable {
    margin-top: get-spacer(3);
  }
}

section#main > .image-divider:first-child {
  margin-top: 0;
}

section.bwiapi_text + section.bwiapi_interview {
  padding-top: 2.5rem; // TODO: might change when images change
}

section.bwiapi_text + section.bwiapi_info1 {
  padding-top: 0;
}
section.bwiapi_text + section.bwiapi_info2 {
  padding-top: 0;
}
section.bwiapi_text + section.bwiapi_imagenet {
  padding-top: 0;
}
section.bwiapi_quote {
  //padding: 0;
}