.image-divider {
  min-height: 30rem;
  position: relative;
  padding-top: get-spacer(6);
  padding-bottom: get-spacer(6);
  margin-bottom: get-spacer(5);
  overflow: hidden;
  
  > img {
    @include absolute-zero();
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
