.route__fuer-zusammenarbeit, .route__fuer-veraenderung {

  @include media-breakpoint-down(lg) {
    main {
      background: black;
    }
  }

  #intro {
    background-position: center top;
    @include media-breakpoint-down(xl) {
      background-size: 170%;
    }
    @include media-breakpoint-down(lg) {
      background: black !important;
      .image__copyright {
        display: none;
      }
      > .structured-content > .structured-content__bg {
        display: none;
      }
    }
    @include media-breakpoint-down(md) {
      > .structured-content > .structured-content__bg {
        display: block;
        margin-bottom: get-spacer(4);
      }
    }

    .structured-content > .container .structured-content__wrapper > .column--left {
      min-height: 56.25vw;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }
  #main {
    section.bwiapi_quote + section.bwiapi_text, section.bwiapi_quote + section.bwiapi_interview, #frage-1 {
      @include media-breakpoint-down(sm) {
        margin-top: get-spacer(4);
        border-top: 2px solid $color-white;
      }
    }
  }
  
  #frage-3 {
    padding-bottom: 1.5rem;
  }
}

.swiper-slide {
  /*Chapter 2*/
  &.slide-1 .slide__bg img{
    object-position: 90% 50%;
  }
  /*Chapter 3.1*/
  &.slide-3 .slide__bg img{
    object-position: 90% 50%;
  }
  /*Chapter 5*/
  &.slide-9 .slide__bg img{
    object-position: 83% 50%;
  }
}

.route__datenschutzerklaerung {
  main {
    margin-top: 115px;
  }
  h2 {
    @extend .h3;
  }
  section {
    margin: get-spacer(5) 0;
  }
  main, footer {
    max-height: none!important;
    overflow: visible !important;
  }

  table {
    @include media-breakpoint-down(sm) {
      font-size: $small-font-size;
    }
  }
}