.interview {
}
.interview__content {
  padding: 2rem;
  position: relative;
}

.interview-item {
  @include make-row();
  margin-bottom: get-spacer(4);
  align-items: flex-end;
  &:last-child {
    margin-bottom: 0;
  }
}

.interview-item__content {
  @include make-col-ready();
  @include make-col(6);
  border-bottom: 2px solid $white;
  padding: get-spacer(3);
  position: relative;

  .interview--right & {
    @include make-col-offset(3);
  }
  .interview--left & {
    order: 2;
  }
  @include media-breakpoint-down(md) {
    @include make-col(8);
    .interview--right & {
      @include make-col-offset(0);
    }
  }

  @include media-breakpoint-down(xs) {
    order: 2;
    @include make-col(12);
    .interview--right & {
      @include make-col-offset(0);
    }
  }
}

.interview-item__picture {
  @include make-col-ready();
  @include make-col(3);
  border-bottom: 2px solid $white;

  .interview--left & {
    order: 1;

    img {
      left: auto;
      right: 0;
    }

    .image__copyright {
      left: 0;
      right: initial;
    }
  }

  img {
    position: absolute;
    max-width: none;
    left: 0;
    bottom: 0;
    height: 400px;
  }
  .image__copyright {
    writing-mode: initial;
    left: initial;
    right: 0;
  }
  @include media-breakpoint-down(md) {
    min-height: 400px;
  }
  @include media-breakpoint-down(xs) {
    @include make-col(12);
    @include make-col-offset(0);
    border: none;
    min-height: 300px;
    img {
      left: 25%;
      height: 300px;
    }
    .interview--left & {
      order: 1;
      img {
        right: 25%;
      }
    }
  }
}

.interview-item__name {
  color: $color-red;
  font-weight: $font-weight-bold;
  margin-bottom: get-spacer(4);
  display: inline-block;
}
