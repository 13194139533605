.intro {
  hr {
    @include make-col(12);
  }
}

.intro__column-text {
  z-index: 2;
  @include make-col-ready();
  @include make-col(8);

  .intro--right & {
    @include make-col-offset(4);
  }
}

.intro__column-image {
  z-index: 1;
  position: fixed;
  bottom: 0;
  @include make-col(4);

  .intro--right & {
    left: 0;
  }
  .intro--left & {
    right: 0;
  }
}

#intro .image__copyright {
  position: fixed;
  z-index: -1;

  @include media-breakpoint-down(xs) {
    display: none;
  }
}