#main-nav {
  @include make-col(8);
  margin: auto;
  @include media-breakpoint-down(sm) {
    @include make-col(12);
  }

  .nav-item a.active {
    color: $color-red;
  }
}

.navbar-nav {
  // scale down for 13" noteboots to reduce the probability of an overflow
  @media (min-width: 992px) and (max-height: 900px) {
    // TODO: does not seem to be necessary any more as now overflow is possible
    // transform-origin: top center;
    // transform: scale(0.8);
  }
}

.header-navbar__label {
  flex: 1;
  @include media-breakpoint-down(sm) {
    font-size: 16px;
  }
}

.nav-item {
  font-size: 1.375rem;
  a {
    display: block;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
  &.main-item {
    padding: 1rem 0 0.5rem;
    border-bottom: 2px solid $white;
  }
  &.sub-item {
    padding: 0.5rem 0 0.25rem;
    a {
      margin-bottom: 0.25rem;
    }
  }
}

.navbar-brand {
  padding: 4px 0;
  margin-right: 0;
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
  img {
    width: auto;
    height: 65px;
    max-width: none;
    @include media-breakpoint-down(sm) {
      height: 46px;
    }
  }
}

.navbar-dark .navbar-toggler {
  border: none;
  &:hover,
  &:focus {
    outline: none;
    border: none;
  }

  margin-left: -0.75rem;
}
.navbar-toggler .icon-bar {
  background-color: white;
  display: block;
  width: 35px;
  height: 1px;
  border-radius: 0px;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.navbar-toggler .icon-bar + .icon-bar {
  margin-top: 9px;
}
/* ANIMATED X */
.navbar-toggler .icon-bar:nth-of-type(1) {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-toggler .icon-bar:nth-of-type(2) {
  opacity: 0;
}

.navbar-toggler .icon-bar:nth-of-type(3) {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}

/* ANIMATED X COLLAPSED */
.navbar-toggler.collapsed .icon-bar:nth-of-type(1) {
  transform: rotate(0);
}

.navbar-toggler.collapsed .icon-bar:nth-of-type(2) {
  opacity: 1;
}

.navbar-toggler.collapsed .icon-bar:nth-of-type(3) {
  transform: rotate(0);
}
/* END ANIMATED X */

.navbar-collapse {
  margin-bottom: get-spacer(3);
  @include media-breakpoint-down(md) {
    max-height: calc(100vh - 60px);
    overflow: auto;
    padding-bottom: 15px;
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
  }
}
