.callout {
  @include font-size($h2-font-size);
  font-weight: $font-weight-bold;
  text-align: center;
}

.col__bwiapi_callout {
  @include make-col(10);
  @include make-col-offset(1);
  @include media-breakpoint-down(sm) {
    @include make-col(12);
    @include make-col-offset(0);
  }
}

.callout__content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
  @include bwi-border($white);
  padding: get-spacer(6);

  @include media-breakpoint-down(md) {
    padding: get-spacer(5);
  }
  @include media-breakpoint-down(md) {
    padding: get-spacer(4);
  }
}
