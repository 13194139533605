.kpis__row {
  @include make-row();
  justify-content: center;
}
.kpi {
  @include make-col-ready();
  @include make-col(4);

  // margin: 3.75rem; // 60px
  @include media-breakpoint-down(sm) {
    @include make-col(8);
    padding-left: 0;
    padding-right: 0;
  }

  @extend %mb-col;
}

.kpi {
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.kpi__inner {
  @include absolute-zero(15px);
  top: 0;
  bottom: 0;

  padding: get-spacer(2);

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  border: $border-width solid $border-color;
  @include media-breakpoint-down(sm) {
    left: 0;
    right: 0;
  }
}

.kpi__title {
  font-size: $h3-font-size;
  @include media-breakpoint-down(md) {
    font-size: $h4-font-size;
  }
  font-weight: bold;
  position: absolute;
  top: get-spacer(2);
}

.kpi__value {
  color: $color-red;
  text-align: center;
  position: absolute;
  top: get-spacer(2);
  bottom: get-spacer(2);
  left: get-spacer(2);
  right: get-spacer(2);
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: bold;
  font-size: $display4-size;
  @include media-breakpoint-down(md) {
    font-size: $display3-size;
  }
  @include media-breakpoint-down(xs) {
    font-size: $display4-size;
  }
  .kpi--lg & {
    font-size: $display1-size;
    @include media-breakpoint-down(md) {
      font-size: $display2-size;
    }
    // @include media-breakpoint-down(xs) {
    //   font-size: $display1-size;
    // }
  }
  .kpi--md & {
    font-size: $display3-size;
    @include media-breakpoint-down(md) {
      font-size: $display4-size;
    }
    @include media-breakpoint-down(xs) {
      font-size: $display3-size;
    }
  }
}

.kpi__text {
  position: absolute;
  bottom: get-spacer(2);
  white-space: break-spaces;
  text-align: center;
}
