.image-bubbles {
  position: relative;
  svg {
    position: absolute;
    z-index: 0;
    top: 0;
  }
}

.image-bubbles__wrapper {
  @include make-row();
  justify-content: center;
}
.image-bubble {
  transition: all 1s $easeSine;
  transform-origin: center;
  transform: scale(0.25);
  opacity: 0;
  &.-in-view {
    transform: scale(1);
    opacity: 1;
  }

  @include media-breakpoint-down(sm) {
    &:last-child {
      margin-bottom: 0;
    }
  }

  position: relative;
  z-index: 1;
  @include make-col-ready();
  @include make-col(3);
  margin-bottom: get-spacer(5);

  @include media-breakpoint-down(md) {
    @include make-col(6);
  }
  @include media-breakpoint-down(sm) {
    @include make-col(12);
  }

  [data-items="5"] & {
    @include make-col(4);
    @include media-breakpoint-down(md) {
      @include make-col(6);
    }
    @include media-breakpoint-down(sm) {
      @include make-col(12);
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.image-bubble__image-wrapper {
  max-width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin: 0 auto;

  border: $border-width solid $border-color;

  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  @include media-breakpoint-down(md) {
    max-width: 50%;
  }

  [data-items="5"] & {
    max-width: percentage(3/4);
    
    @include media-breakpoint-down(md) {
      max-width: 50%;
    }
  }
}

.image-bubble__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  padding: 5px;
  border-radius: 50%;
  background-color: black;
}

.image-bubble__teaser {
  margin-top: get-spacer(3);
  background: $bg-black;
  //padding: get-spacer(3);
  text-align: center;
}

.image-bubble__teaser__title {
  font-weight: $font-weight-bold;
  margin-bottom: get-spacer(3);
  text-align: center;
}
