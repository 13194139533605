.top-arrow {
  display: none; // TODO: tmp or durable solution with ToOverview.js
  position: absolute;
  right: 22px;
  top: 1rem;
  z-index: $zindex-sticky;
  transition: $transition-base;
  @include media-breakpoint-down(xs) {
    right: 1rem;
  }
  &:hover {
    transform: scale(1.2);
  }
}
