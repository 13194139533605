.col__bwiapi_twitter {
  @include make-col(8);
  @include make-col-offset(2);
  @include media-breakpoint-down(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
}

.twitter-post {
  margin-top: get-spacer(5);
  @include bwi-border($color-havelock-blue);
  &:before {
    content: '';
    display: block;
    background: url(./../../icon-twitter.svg) no-repeat;
    background-size: 50%;
    position: absolute;
    background-color: $color-havelock-blue;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-position: 60% 55%;
    transform: translateX(-50%) translateY(-50%);
    border: 2px solid $white;
    @include media-breakpoint-down(sm) {
      transform: translateX(-50%) translateY(-50%) scale(0.6);
    }
  }
}
.twitter-post__inner {
  padding: get-spacer(5) get-spacer(5) get-spacer(4);
  @include media-breakpoint-down(sm) {
    padding: get-spacer(4);
  }
}
.twitter-post__content {
  border-bottom: 2px solid $color-havelock-blue;
  @include font-size($font-size-lg);
  margin-bottom: get-spacer(3);
  padding-bottom: get-spacer(3);
  @include media-breakpoint-down(xs) {
    @include font-size(1.25rem);
  }
}

.twitter-post__author {
  font-weight: $font-weight-bold;
  margin-bottom: get-spacer(2);
}

.twitter-post__profile {
  color: $color-havelock-blue;
}
