footer {
  max-height: 0px;
  overflow: hidden;
  background-color: $black;

  .image__copyright {
    z-index: 2;
  }
}

.footer__content {
  padding-top: get-spacer(5);
  padding-bottom: get-spacer(1);
  position: relative;
  z-index: 3;

  [class*='col'] {
    @extend %mb-col;
  }
  @include media-breakpoint-down(sm) {
    text-align: center;
    padding-bottom: get-spacer(6);
  }
}
.nav#footer-nav {
  justify-content: center;
  .nav-link {
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    margin-bottom: 0;
    &:hover {
      color: $red;
    }
  }
}
.footer__copyright {
  font-size: $font-size-sm;
  font-weight: $font-weight-light;
}
.footer__social-media-list {
  @include list-unstyled();
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @include media-breakpoint-down(sm) {
    margin-bottom: 1.5rem;
  }

  .social-media__item {
    @include size(30px);
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    & + .social-media__item {
      margin-left: 20px;
    }
  }

  @include media-breakpoint-down(md) {
    justify-content: center;
  }
}
