.quote {
  @include make-row();
  align-items: center;
  position: relative;
  blockquote {
    font-weight: $font-weight-bold;
  }
  &.-font-normal blockquote {
    @include font-size($h3-font-size);
    font-weight: 700;
  }
  &.-font-large blockquote {
    @include font-size($h2-font-size);
  }
  min-height: 60vh;
  &.-no-image {
    min-height: auto;
  }
}

.quote__name {
  font-weight: $font-weight-bold;
}

.quote__image {
  z-index: 1;
  @include make-col-ready();
  @include make-col(2);
  height: 60vh;

  @include media-breakpoint-down(lg) {
    @include make-col(4);
  }

  @include media-breakpoint-down(sm) {
    @include make-col(12);
  }

  img {
    height: 100%;
    width: auto;
    max-width: none;
    position: absolute;
    max-width: 700px;
    max-height: 700px;
  }
  .-image-left & {
    img {
      right: 0;
      transform: translateX(16%);
      @include media-breakpoint-down(sm) {
        transform: translateX(0%);
      }
    }
  }

  .-image-right & {
    @include media-breakpoint-up(md) {
    order: 2;
  }
    img {
      left: 0;
      transform: translateX(-16%);
      @include media-breakpoint-down(sm) {
        transform: translateX(0%);
      }
    }
  }
}
.quote__content {
  z-index: 2;
  @include make-col-ready();
  @include make-col(10);

  .image__copyright {
    position: relative;
    margin-top: get-spacer(4);
    right: initial;
    bottom: initial;
  }

  @include media-breakpoint-down(lg) {
    @include make-col(8);
  }

  @include media-breakpoint-down(sm) {
    @include make-col(12);
    display: flex;
    flex-direction: column;
    .image__copyright { 
      order: -1;
      margin-top: 0;
      position: absolute;
      top: -1rem;
      right: 0;
    }

  }
  .-no-image & {
    @include make-col(10);
    @include media-breakpoint-down(lg) {
      @include make-col(12);
    }
  }
}
