.video-player {
  position: relative;
  @extend .embed-responsive;
  @extend .embed-responsive-16by9;
  border: 1px solid $color-gallery;
}

.video-player__video {
  width: 100%;
  height: auto;
  display: block;
  border: 2px solid $color-red;
  &:focus,
  &:active {
    outline: none;
  }
}

.video-player__overlay {
  @include absolute-zero();
  @extend %centerize-flex;
  &.-playing {
    display: none;
  }
}

.video-player__overlay__play {
  cursor: pointer;
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 1px solid $color-gallery;
  background: transparent;
  @extend %centerize-flex;
  &:before {
    content: '';
    display: block;
    background: url(https://broschuere.bwi.de/assets/icons/icon-play.svg) no-repeat center;
    background-size: contain;
    position: absolute;
    top: 25px;
    left: 39px;
    width: 31px;
    height: 50px;
  }
}

.video-player__description {
  margin-top: get-spacer(2);
  font-size: $small-font-size;
  font-style: italic;
}
