%in-view {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  transition-timing-function: $easeSine; /* easeInOutSine */
}

.-in-view {
  @extend %in-view;
}

.bwiapi_text,
.quote,
.callout,
.flex-image,
.expandable,
.twitter-post,
.twitter-post::before,
.video-player,
.video-player__description,
.kpi,
.structured-content__bg,
.quote__image,
.person,
.person__image__wrapper,
.interview-item {
  opacity: 0;
}

.-in-view {
  .quote__image,
  .person__image__wrapper,
  &.video-player + .video-player__description {
    @extend %in-view;
    animation-name: fadeInUp;
  }
}

.-in-view {
  .structured-content__bg {
    @extend %in-view;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    animation-name: fadeIn;
  }
}
.-in-view {
  .twitter-post::before {
    @extend %in-view;
    animation-name: fadeIn;
  }
}

.-in-view {
  &.interview--left {
    @extend %in-view;
    animation-name: fadeInLeft;
  }
}

.-in-view {
  &.interview--right {
    @extend %in-view;
    animation-name: fadeInRight;
  }
}

.-in-view {
  &.bwiapi_text,
  &.quote,
  &.callout,
  &.flex-image,
  &.expandable,
  &.twitter-post,
  // &.image-divider,
  &.video-player,
  &.kpi,
  &.person {
    animation-name: fadeInUp;
  }
}

.accordion__body {
  transition: height 1s $easeCubic !important;
}

.navbar-collapse {
  transition: height 1s $easeCubic !important;
}
