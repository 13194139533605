.infobox {
  background: $color-red;
  padding: get-spacer(4);
  .flex-image {
    border: none !important;
  }

  &.infobox--transparent {
    background: transparent;
  }
}

.infobox__border {
  border: $border-width solid $border-color;
  border-top-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  padding: get-spacer(4);
}
